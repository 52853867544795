import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base"
import { regular12, regular16, regular18, regular24 } from "./typography"
import { StyledTab, StyledTabSelected, StyledTabsList } from "./tabs"



const Article = styled.article`
  ${baseGridStyles}

  &.event-post {
    padding: 0;
    @media screen and (min-width: ${breakpoints.md}px) {
      padding-top: 1.6rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      padding-top: 3.2rem;
    }
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 0 auto;
  }
`

const ArticleHeader = styled.header`
  grid-column: 1 / 5;
  padding: 0;
  position: relative;
  ${props => props.align && 'text-align: center'};
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: 1 / 7;
    margin: 0 0 0.8rem;
    padding: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 1 / 13;
    margin: 0 0 1.6rem;
  }

  h1{
    ${props => props.noMargin ? "margin-bottom: 0" : "margin-bottom: 1.6rem"};
    color: var(--light-green-darker);

    &.offscreen {
      position: absolute;
      top: -5000px;
      left: -5000px;
    }
  }
  time {
    line-height: 1.6rem;
    border-top: 1px solid var(--grey-lighter);
    border-bottom: 1px solid var(--grey-lighter);
    padding: 0.8rem 0;
    @media screen and (min-width: ${breakpoints.md}px) {
      padding: 1.6rem 0;
    }
  }
  div {
    position: absolute;
    bottom: 0.5rem;
    right: 0.8rem;
    span {
      @media screen and (min-width: ${breakpoints.md}px) {
        position: relative;
        top: 0;
        left: 0;
      }
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      bottom: 1.4rem;
    }
    a:hover,
    a:focus {
      border-bottom: none;
    }
  }
 
  
`

const ArticleDate = styled.time`
  margin: 0;
  color: var(--grey-mid-dark);
  visibility: ${props => props.offScreen ? 'hidden' : 'visible'};
  display: ${props => props.offScreen ? 'none' : 'block'};
  ${regular12};
  span.publishedon {
    display: none;
    @media screen and (min-width: ${breakpoints.md}px) {
      display: inline-block;
    }
  }
`

const ArticleAuthorInfo = styled.address`
  margin: 1.6rem 0 0;
  color: var(--grey-mid-dark);
  ${regular12};
    text-indent: -5000px;
    visibility: hidden;
    width: 0;
    display: none;

    &.author {
      display: block;
      visibility: visible;
      width: auto;
      text-indent: 0px;
      text-align: center;
      margin: 0;
      @media screen and (min-width: ${breakpoints.md}px) {
        ${regular16};
      }


      a {
        margin-left: 0rem;
      }
    }
  a {
    margin-left: 3.4rem;
    position: relative;
    border-bottom: 0;
  }
`

const ArticleSection = styled.section`
  grid-column: 1 / 5;
  width: 100%;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: 1 / 7;
    margin: 0;
    padding: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 1 / 8;
  }

  &.event-single {
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: 1 / 9;
      margin-top: 3rem;
    } 
    @media screen and (min-width: ${breakpoints.l}px) {
      margin-top: 4rem;
    } 

    p video {
      width: -webkit-fill-available;
    }
    .react-tabs__tab-panel {
      margin-top: 1.6rem;
    }
  }

  a {
    border-bottom: 1px dotted var(--light-green-dark);
    &:visited {
      border-bottom: 1px dotted var(--light-green-darker);
    }

    &:hover,
    &:active,
    &:focus {
      border-bottom: 1px dotted var(--light-green-dark);
    }
  }

  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    margin: 1.6rem 0 0 0;
    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 2.4rem 0 0 0;
    }

    @media screen and (min-width: ${breakpoints.l}px) {
      margin: 3.2rem 0 3.2rem 0;
    }
  }

  h2 + h3, 
  h3 + h4, 
  h4 + h5, 
  h5 + h6 {
    margin-top: 0.8rem;
  }

  h2 + h3, 
  h3 + h4, 
  h4 + h5, 
  h5 + h6 {
    margin-top: 0.8rem;
    margin-bottom: 0;
  }

  address {
    font-style: italic;
  }
  p {
    @media screen and (min-width: ${breakpoints.md}px) {
      ${regular18}
    }
  }
  * + p,
  * + pre,
  * + ul,
  * + ol,
  * + dl,
  * + dt,
  * + blockquote,
  * + address {
    margin-top: 1.6rem;
  }

  * + ol li,
  * + ul li {
    margin-bottom:0.8rem;
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-bottom:1.6rem;
    }
  }


  blockquote {
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-left: 0;
      width: auto;
    }
  }
  .gatsby-image-wrapper {
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-left: 0;
      margin-right: 0;
    }
    @media screen and (min-width: 1304px) {
      margin: 0 auto;
    }
  }

  address {
    font-style: italic;
  }
  figure.align-left {
    @media screen and (min-width: ${breakpoints.sm}px) {
      width: 50%;
      text-align: left;
    }
  }
  figure:first-of-type{
      margin-left: -1.6rem;
      width: 100vw;
      min-width: 32rem;
      margin-top: 1.6rem;
      @media screen and (min-width: ${breakpoints.sm}px) {
        margin-left: 0;
        width: auto;
      }

      figcaption {
        margin: 0.6rem 1.6rem 0;
      }
  }
  .calendly-inline-widget {
    margin-left: -1.6rem;

    @media screen and (min-width: ${breakpoints.sm}px) {
      width: 700px;
    }
  }
  .social-links {
    margin-top: 1.6rem;
    line-height: 1.6rem;
    border-top: 1px solid var(--grey-lighter);
    border-bottom: 1px solid var(--grey-lighter);
    padding: 0.8rem 0;
    justify-content: start;
    @media screen and (min-width: ${breakpoints.sm}px) {
      justify-content: end;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      padding: 1.6rem 0;
      margin-top: 2.4rem;
    }
    span {
      position: relative;
      left: 0;
      top: 0;
      ${regular12}
    }
    ul {
      margin-top: 0;
    }
    a,
    a:hover,
    a:active {
      border-bottom: 0;
    }
  }

  .react-tabs .sticky {
    padding-top: 0.8rem;
    background: var(--white);
    width: 100%;
    padding-bottom: 0.8rem;
    z-index:2;
  }
  .react-tabs__tab-list {
    ${StyledTabsList}
  }
  .react-tabs__tab {
    ${StyledTab}
    margin-left:-0.8rem;
    font-weight: 700;
  }

  .react-tabs__tab--selected {
    ${StyledTabSelected}
  }

  .react-tabs__tab-panel {
    ul.testimonials {
      list-style-type: none;
      padding-left: 0;

      blockquote {
        border-left: 0;
        padding: 0;
        border-bottom: 1px solid var(--grey-dark);
        padding-bottom: 3.2rem;

        .react-player {
          width: auto;
          height: 320px!important;
          @media screen and (min-width: ${breakpoints.l}px) {
            height: 500px!important;
          }
        }
        .react-player__preview {
          min-height: 230px;
          @media screen and (min-width: ${breakpoints.sm}px) {
            min-height: 360px;
          }
          @media screen and (min-width: ${breakpoints.l}px) {
            min-height: 460px;
          }
        }
        p {
          font-style: normal;

          img.emoji {
            width: 3rem;
            display: block;
          }
        }

        @media screen and (min-width: ${breakpoints.sm}px) {
          padding-left: 1.6rem;
        }
      }
    }
  }

`

const ArticleTagsList = styled.ul`
  margin-top: 1.6rem;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding-left: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    padding-left: 0;
  }
  li {
    margin-right: 0.8rem;
    display: inline-flex;
  }

  a {
    text-decoration: none;
    ${regular12};
    border: 1px solid var(--grey-mid);
    padding: 0.4rem 0.8rem;
    border-bottom: 1px solid var(--grey-mid);

    &:hover,
    &:active,
    &:focus {
      border: 1px solid var(--light-green-dark);
      border-bottom: 1px solid var(--grey-mid);
    }
  }
`

const ArticleAside = styled.aside`
  background-color: ${props => !props.background ? "var(--light-green-light);" : props.background};
  border: ${props => !props.border ? "1px solid var(--grey-light);" : "0px solid var(--grey-light);"};
  width: 100%;
  ${baseGridStyles}
  padding: 1.6rem;
  align-items: start;
  height: max-content;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0.8rem 0 0;
    border-radius: 1rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin-top: ${props => props.marginTop ? "6rem;" : "0"}
    display: block;
    grid-column: ${props => props.setFullWidth ? "1 / 13" : "9 / 13"};
    grid-template-columns: repeat(4,1fr);
  }
  abbr {
    text-decoration: none;
  }
  h2 {
    grid-column: span 4;
    text-align: left;
    margin-top: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin-top: 2.4rem;
      grid-column: span 6;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      margin: 0 0 1.6rem;
      grid-column: span 4;
    }

    &.other-event-header {
      color: #fff;
      ${regular24}
    }
  }
`


export { Article, ArticleHeader, ArticleSection, ArticleTagsList, ArticleDate, ArticleAuthorInfo, ArticleAside }