import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base";
import { StyledTabsList, StyledTab, StyledTabSelected } from "../styledComponents/tabs"
import { regular14, regular16 } from "./typography";

const Section = styled.section`
  ${baseGridStyles}
  background: ${props => props.background ? props.background : 'transparent'};
  @media screen and (min-width: ${breakpoints.l}px) {
    max-width: ${props => props.background ? '100%' : 'auto'};
  }
  section {
    padding: 0;
  }
  p{
    margin: 0;
  }

  &.shm-program {
    ${baseGridStyles}
    list-style: none;
    margin: 0;
    .react-tabs {
      grid-column: span 4;
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 6;
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
      }
    }
    .react-tabs__tab-panel {
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
      }
    }

    .react-tabs__tab-list {
      border-bottom: 1px solid var(--light-purple-darker);
      ${StyledTabsList}
      position: relative;
      margin-bottom: 1.6rem;
      margin-top: 0;
      grid-column: span 12;
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: 3 / 11;
        margin-bottom: 1.6rem;
        justify-content: center;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      
      grid-column: span 12;
      &.product-list {
        justify-content: flex-start;
      }
    }
    .react-tabs__tab {
      ${StyledTab}
      margin-left:-0.8rem;
    }
    
    .react-tabs__tab--selected {
      ${StyledTabSelected}
    }
  }
  &.event-practice-day {
    padding: 0;
    .x {
      p{
        ${regular14}
        margin-bottom: 0.8rem;
        margin-top: 0.8rem;
        @media screen and (min-width: ${breakpoints.md}px) {
          ${regular16}
          margin-bottom: 1.6rem;
          margin-top: 1.6rem;
        } 
      }
      .react-tabs__tab-panel button {
        @media screen and (min-width: ${breakpoints.md}px) {
          margin-left: 0;
          // width: 70%;
        }
      }
      .react-tabs__tab-list {
        border-top: 0;
        border-bottom: 0;
        @media screen and (min-width: ${breakpoints.md}px) {
          justify-content: flex-start;
        }
        
        .react-tabs__tab {
          border-radius: 0.6rem;
          background: var(--grey-lighter);
          margin-right:1.6rem;
          margin-left: 0;
          padding: 0.8rem 1.6rem;
          color: var(--black);
          text-align:center;
          flex: auto;
          @media screen and (min-width: ${breakpoints.md}px) {
            flex: none;
          }
          &:hover {
            border-bottom: 0;
          }

          &:last-child{
            margin-right: 0;
          }

          &.react-tabs__tab--selected {
            background: var(--light-purple-mid);
            border-radius: 0.6rem;
            border-bottom: 0;
          }
        }
      }
      grid-column: span 6;
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 12;
      }


    }
    .enquire {
      grid-column: span 6;
      button{ 
        padding: 0;
      }
      fieldset button {
        padding: 1.6rem;
        width: 100%;
        @media screen and (min-width: ${breakpoints.md}px) {
          width: auto;
        }
      }
      @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 12;
      }
    }
  }

  &.pat-program {
    .react-tabs__tab-panel {
      ${baseGridStyles}
      grid-column: span 12;
      padding: 0;
      @media screen and (min-width: ${breakpoints.sm}px) {
        padding: 0;
        grid-column: span 12;
      }
      section:nth-child(1) {
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.md}px) {
          grid-column: span 8;
        }
        
      }
      section:nth-child(2) {
        grid-column: span 4;
        @media screen and (min-width: ${breakpoints.sm}px) {
          grid-column: span 2;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
          grid-column: 9 / 13;
        }
      }

      blockquote {
        grid-column: span 4;
        border-left: 0;
        border-top: 4px solid var(--light-purple-mid);
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;
        &:first-of-type{
          border-top: 0;
        }

        p {
          ${regular16}
        }
        p:first-of-type {
          margin-top: 0;
        }
        p:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &.corporate-wellness {
      ${baseGridStyles}
      grid-column: span 12;
      article {
        ${baseGridStyles}
        padding: 0;
        grid-row-gap: 0;
        grid-column: span 6;
        @media screen and (min-width: ${breakpoints.sm}px) {
          grid-column: span 6;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
          grid-column: span 12;
          padding: 0;
          grid-row-gap: 0;
          margin-bottom: 6rem;
        }

        h1, 
        h2, 
        p, 
        div.gatsby-image-wrapper, 
        ul {
          grid-column: span 6;
        }
        h1 {
          @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: span 12;
            justify-content: center;
            display: flex;
          }
        }
        div.gatsby-image-wrapper {
          @media screen and (min-width: ${breakpoints.md}px) {
            // margin-left: -2.4rem;
            grid-column: 1 / 5;
            grid-row-start: 3;
            grid-row-end: 11;
          }
          @media screen and (min-width: ${breakpoints.l}px) {
            grid-column: 1 / 5;
            grid-row-start: 3;
            grid-row-end: 13;
          }
        }

        
        h2:nth-of-type(1) {
          @media screen and (min-width: ${breakpoints.md}px) {
            margin-lefT: 3.2rem;
          }
        }
        h2:nth-of-type(2) {
          @media screen and (min-width: ${breakpoints.md}px) {
            margin-top: 2.4rem;
            margin-left: 3.2rem;
            grid-column: span 6;
            width: 100%;
            
          }
        }
        p {
          margin-top: 1.6rem;

          &:first-of-type {
            @media screen and (min-width: ${breakpoints.md}px) {
              margin-bottom: 2.4rem;
              grid-column: 3 / 11;
              text-align: center;
              justify-content: center;
              display: flex;
            }
          }

          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(4),
          &:nth-of-type(5),
          &:nth-of-type(6) {
            @media screen and (min-width: ${breakpoints.md}px) {
              grid-column: span 7;
              margin-left: 3.2rem;
            }
          }
        }
        ul {
          &:first-of-type {
            @media screen and (min-width: ${breakpoints.md}px) {
              margin-left: 3.2rem;
              
            }
          }
        }
        

        &.corporate-programs {
          @media screen and (min-width: ${breakpoints.md}px) {
            padding: 0;
            grid-column: span 12;
          }

          @media screen and (min-width: ${breakpoints.md}px) {
            padding: 0;
            grid-column: span 12;
          }
        }

        .universal-photo {
          margin-top: 1.6rem;
        }

        blockquote {
          border-left: 0;
          border-top: 4px solid var(--light-purple-mid);
          margin-bottom: 0;
          margin-top: 0;
          margin-left: 0;
          padding-right: 0;
          padding-left: 0;
          width: 100%;
          @media screen and (min-width: ${breakpoints.md}px) {
            margin-bottom: 2.4rem;
            padding-top: 2.4rem;
          }
          &:first-of-type{
            border-top: 0;
          }

          p {
            ${regular16}
          }
          p:first-of-type {
            margin-top: 0;
          }
          p:last-of-type {
            margin-bottom: 0;
          }

          img {
            @media screen and (min-width: ${breakpoints.l}px) {
              max-width: 250px;
            }
          }
        }
      }

    
    .corporate-testimonials {
      img {
        margin: 0 0 1.6rem;
        width: auto;
      }
    }
  }
  &.corporate-wellness-testimoinals {
        ${baseGridStyles}
        grid-column: span 12;
        article {
          grid-column: span 6;
          @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: span 6;
          }
          @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: 3 / 11;
          }
          h2 {
            @media screen and (min-width: ${breakpoints.md}px) {
              margin-bottom: 3.2rem;
            }
          }
          p {
            margin-top: 1.6rem;
          }
  
          &.corporate-programs {
            @media screen and (min-width: ${breakpoints.md}px) {
              padding: 0;
              grid-column: span 12;
            }
  
            @media screen and (min-width: ${breakpoints.md}px) {
              padding: 0;
              grid-column: span 12;
            }
          }
  
          blockquote {
            border-left: 0;
            border-top: 4px solid var(--light-purple-mid);
            margin-bottom: 0;
            margin-top: 0;
            margin-left: 0;
            padding-right: 0;
            padding-left: 0;
            width: 100%;
            @media screen and (min-width: ${breakpoints.md}px) {
              margin-bottom: 2.4rem;
              padding-top: 2.4rem;
            }
            &:first-of-type{
              border-top: 0;
            }
  
            p {
              ${regular16}
            }
            p:first-of-type {
              margin-top: 0;
            }
            p:last-of-type {
              margin-bottom: 0;
            }
  
            img {
              max-width: 200px;
              margin-bottom: 1.6rem;
              @media screen and (min-width: ${breakpoints.md}px) {
                margin-bottom:2.2rem;
              }
              @media screen and (min-width: ${breakpoints.l}px) {
                max-width: 250px;
              }
            }
          }
      
    }
  &.corporate-wellness-appointment {
    h2, div, button {
      text-align: left;
      grid-column: span 12;
      margin: 0;
      @media screen and (min-width: ${breakpoints.sm}px) {
        text-align: center;
        grid-column: span 6;
        margin: 0 auto;
      }
      @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 12;
      }
    }
  }
  
  .react-tabs {
    ${baseGridStyles}
    padding: 1.6rem 0;
    grid-row-gap: 0;
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-row-gap: 0;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-row-gap: 0;
    }
    @media screen and (min-width: ${breakpoints.lg}px) {
      grid-row-gap: 0;
    }
  }
  .react-tabs__tab-list {
    border-bottom: 1px solid var(--light-purple-darker);
    ${StyledTabsList}
    position: relative;
    margin-bottom: 1.6rem;
    margin-top: 0;
    grid-column: span 12;
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: 3 / 11;
      margin-bottom: 1.6rem;
      justify-content: center;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    
    grid-column: span 12;
    &.product-list {
      justify-content: flex-start;
    }
  }
  .react-tabs__tab {
    ${StyledTab}
    margin-left:-0.8rem;
  }
  
  .react-tabs__tab--selected {
    ${StyledTabSelected}
  }

  .react-tabs__tab-panel {
    grid-column: span 12;
    p {
      margin-top: 1.6rem;
    }

    hr {
      margin: 1.6rem 0;
      @media screen and (min-width: ${breakpoints.md}px) {
        margin: 2.4rem auto;
      }
      @media screen and (min-width: ${breakpoints.l}px) {
        margin: 3.2rem auto;
      }
    } 

    ul.testimonials {
      list-style-type: none;
      padding-left: 0;

      blockquote {
        padding-top: 0;
        @media screen and (min-width: ${breakpoints.sm}px) {
          margin-bottom: 1.6rem;
          margin-left: 0;  
          width: 100%;
        }
        @media screen and (min-width: ${breakpoints.md}px) {
          margin-bottom: 3.2rem;
          margin-left: 0;
          // width: 70%;
        }
        @media screen and (min-width: ${breakpoints.l}px) {
          margin-bottom: 3.2rem;
          margin-left: 0;
          // width: 70%;  
        }

        img {
          width: 2.5rem;
          height: 2.5rem;
        }

      }
    }
  }

  .buy-now-button {
    grid-column: span 12;
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: 3 / 11;
    }
  }
`

const SectionBlock = styled(Section)`
  display: block;
  max-width: 130.4rem;
  margin: 0 auto;
  padding: calc((16 / 320) * 100%);
  @media screen and (min-width: ${breakpoints.sm}px) {
    padding: calc((24 / ${breakpoints.sm}px) * 100%);
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    padding: calc((24 / ${breakpoints.md}px) * 100%);
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    padding: calc((32 / ${breakpoints.l}px) * 100%);
  }

  p{
    max-width: 86.2rem;
    margin: 0 auto;

  }
  h2{
    margin: 0 0 1.6rem;
    @media screen and (min-width: ${breakpoints.sm}px) {
      margin: 0 0 2.4rem;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      margin: 0 0 3.2rem;
    }
  }
`

const SectionBlockEmbedCalendar = styled(Section)`
  max-width: 100%;
  iframe {
    grid-column: span 4;
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: 1 / 8;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: span 12;
      margin: 0 auto;
    }
  }
`

const SectionHeading = styled.div`
  text-align: ${props => props.align === 'center' ? 'center' : 'left'};
  margin:0;
  grid-column: span 4;
  ${props => props.offScreen && 'position:absolute;top:-50000px;left:-5000px;'};

  &.my-account__header {
    font-weight: 500;
  }
  
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: 1 / 8;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: span 12;
    text-align: center;
    max-width: 80rem;
    margin: 0 auto;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    max-width: 120rem;
  }

  span {
    position: relative;
    z-index: 1;
    display: block;
    margin: 2.4rem 0;
    &:before {
        border-top: 2px solid #dfdfdf;
        content:"";
        margin: 0 auto; /* this centers the line to the full width specified */
        position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
        top: 50%; left: 0; right: 0; bottom: 0;
        width: 95%;
        z-index: -1;
    }

    em { 
        font-style: normal;
        background: #fff; 
        padding: 0 15px; 
    }
  }
  strong {
    display: inline-block;
    background-color: var(--blue-mid);
    color: var(--white);
    padding: 0.8rem 1.6rem;
    @media screen and (min-width: ${breakpoints.md}px) {
      display: block;
      width: max-content;
      margin: 0 auto;
    }
  }
`

const SectionWrapper = styled.div`
  max-width: 130.4rem;
  margin: 0 auto;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0 calc((24 / 720) * 100%) 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 0 calc((24 / 1024) * 100%) 0;
  }
  @media screen and (min-width: 1304px) {
    width: 100%;
    margin: 0 auto;
  }
`

const SectionLede = styled.p`
  text-align: left;
  margin-bottom: 1.6rem;
  grid-column: span 4;

  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column-start: 2;
    grid-column-end: 6;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column-start: 4;
    grid-column-end: 10;
    text-align: center;
  }
`

const SectionLedeDiv = styled.div`
  text-align: left;
  margin-bottom: 1.6rem;
  grid-column: span 4;

  @media screen and (min-width: ${breakpoints.sm}px) {
    text-align: left;
    grid-column-start: 2;
    grid-column-end: 6;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column-start: 4;
    grid-column-end: 10;
  }

  p {
    margin-bottom: 1.6rem;

    &:last-child{
      margin-bottom: 0;
    }
  }
`

const SectionHeaderLedeDiv = styled.div`
  text-align: ${props => props.align ? props.align : 'center'};
  margin-bottom: 1.6rem;
  grid-column: span 4;

  @media screen and (min-width: ${breakpoints.sm}px) {
    text-align: ${props => props.align ? props.align : 'center'};
    grid-column-start: ${props => props.align === 'left' ? '1' : '2'};
    grid-column-end: 6;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column-start: 4;
    grid-column-end: 10;
    text-align: center;
  }

  p {
    margin-bottom: 1.6rem;

    &:last-child{
      margin-bottom: 0;
    }
  }
`

const SectionSingleProduct = styled(Section)`
  padding-top: 0;
  @media screen and (min-width: ${breakpoints.sm}px) {
    padding-top: 1.6rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    padding-top: 2.4rem;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    padding-top: 3.2rem;
  }
`

const SectionCorporateLogoList = styled.section`
  padding: 1.6rem 0;
  background: ${props => props.background ? props.background : 'transparent'};
  @media screen and (min-width: ${breakpoints.md}px) {
    padding: 4.4rem 0;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    padding: 4.8rem 0;
    max-width: ${props => props.background ? '100%' : 'auto'};
  }

  p {
    margin-top: 1.6rem;
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-top: 2.4rem;
    }
    a {
      margin: 0 1.6rem;
      @media screen and (min-width: ${breakpoints.md}px) {
        width: 40rem;
      }
    }
  }
`
export { 
  Section, 
  SectionWrapper, 
  SectionHeading, 
  SectionHeaderLedeDiv, 
  SectionLede, 
  SectionBlock, 
  SectionBlockEmbedCalendar,
  SectionLedeDiv,
  SectionSingleProduct,
  SectionCorporateLogoList
}